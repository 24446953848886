import { ReactNode } from 'react';
import './styles.scss';

interface iImageHeader {
  backgroundImg: string,
  logo?: string,
  title: string,
}

const heroImage = ({
  backgroundImg,
  logo,
  title,
}: iImageHeader) => {

  return (
    <>
      <div className="forAccessibility">{title}</div>
      {logo && <img className="logo" src={logo} />}
      <img className="background" src={backgroundImg} />
    </>
  )
};

export default heroImage;