import { Row, Col, Button } from "reactstrap";
import '../styles/styles.scss';

const WorkPage = () => {

    return (
        <div className="content work">

            <p>Below are some examples of my work from the past five years:</p>

            {/* April 2022 - May 2023 */}
            <div className="item">
                <Row>
                    <Col xs="12" sm="6">
                        <a href="https://www.heinz.com/" target="_blank" rel="noreferrer" id="heinz"><img src="/images/work/heinz.png" alt="Heinz" /></a>
                    </Col>
                    <Col xs="12" sm="6">
                        <h2>Heinz</h2>
                        <p>For well over a year, I played a big part in helping Heinz to grow and improve their users' digital experience. I researched and implemented the use of themes to cater for multiple Heinz brands using TailwindCSS and Storybook. I built a large number of React components in Storybook with variable properties to accommodate different use cases, broken down into atoms, molecules and organisms with themes from figma designs and unit tests using React Testing Library. I also worked with Algolia to query and filter Heinz products for use in the frontend and extracted data from the mapped Contentful API to feed into components. I developed a CLI tool using OClif for developers to migrate content and content models between contentful environments and used Auth0 to create a template for Heinz and other brands to authenticate users and give them access to personalised content.</p>
                        <h3>Skills used</h3>
                        <p>React, Next JS, Tailwind CSS, Headless UI, Algolia, OClif, Auth0, React Testing Library, Storybook, Figma</p>
                        <Button href="https://www.heinz.com/" target="_blank" rel="noreferrer">Visit Site</Button>
                    </Col>
                </Row>
            </div>

            <hr />

            {/* April 2021 - April 2022 */}
            <div className="item">
                <Row>
                    <Col xs="12" sm="6">
                        <a href="https://www.modernatx.com/" target="_blank" rel="noreferrer" id="moderna"><img src="/images/work/moderna.png" alt="Moderna" /></a>
                    </Col>
                    <Col xs="12" sm="6">
                        <h2>Moderna</h2>

                        <p>During Covid, Moderna were leaders in providing vaccines across the world. We were tasked with finding a way that physicians could register their details for consumer use. We developed a website made up from a large pool of newly created components in Storybook which was known as our web component library. I developed a large number of AODA compliant React components from figma designs for mobile, tablet and desktop and corresponding Contentful content models. This enabled the client to construct pages from the large pool of content models and author content. We took advantage of the ability to pass props to Styles components for various use cases. I was an important part of the development team for an entire year.</p>
                        <h3>Skills used</h3>
                        <p>React, Next JS, Styled Components, React Testing Library, Storybook, Figma</p>
                        <Button href="https://www.modernatx.com/" target="_blank" rel="noreferrer">Visit Site</Button>
                    </Col>
                </Row>
            </div>

            <hr />

            {/* Jul 2018 - Dec 2019 */}
            <div className="item">
                <Row>
                    <Col xs="12" sm="6">
                        <a href="https://www.telus.com" target="_blank" rel="noreferrer" id="telus"><img src="/images/work/telus.png" alt="Telus" /></a>
                    </Col>
                    <Col xs="12" sm="6">
                        <h2>Telus</h2>

                        <p>Initially, worked as part of a large team on the Telus Configure app for the Mobility department. The purpose was to allow customers to customise a mobile package. There was various business logic built around the app based on the type of customer you were, which would determine what plans and services would be visible. Massaged relevant data from the API deriving from the backend and Contenful. Followed TDS (Telus Design System) guidlelines. As a member of a consultancy team, worked closely with Telus Health and a Telus Sitebulder team to deliver 150 Contenful based pages. Demonstrated proficiency with the CMS to develop these pages and provide assistance and training to Telus Health team members. Developed and adapted React based components for Sitebuilder.</p>
                        <h3>Skills used</h3>
                        <p>React, Redux, Javascript, ES6, Sitebuilder, Contentful, Styled Components, Jest/Enzyme</p>
                        <Button href="https://www.telus.com" target="_blank" rel="noreferrer">Visit Site</Button>
                    </Col>
                </Row>
            </div>

            <hr />

            {/* May 2023 - July 2023 */}
            <div className="item">
                <Row>
                    <Col xs="12" sm="6">
                        <a href="https://www.tryaioli.com/" target="_blank" rel="noreferrer" id="tryaioli"><img src="/images/work/tryaioli.png" alt="Try AI Oli" /></a>
                    </Col>
                    <Col xs="12" sm="6">
                        <h2>Try Ai.Oli</h2>

                        <p>A Heinz branded chat bot was created to help consumers develop unique recipes. I was involved in integrating the Chat GPT API into our site and filtering our virtual chef to steer users towards the next meal recipe. I was also responsible for developing CSS Animations with aurora effects using Framer and building and themeing new components using React and TailwindCSS.</p>
                        <h3>Skills used</h3>
                        <p>Chat GPT, React, Next JS, Framer Motion, Headless UI, CSS Animations, Contentful, React Testing Library</p>
                        <Button href="https://www.tryaioli.com/" target="_blank" rel="noreferrer">Visit Site</Button>
                    </Col>
                </Row>
            </div>

            <hr />

            {/* July 2023 - Nov 2023 */}
            <div className="item">
                <Row>
                    <Col xs="12" sm="6">
                        <a href="https://yourstudio.com" target="_blank" rel="noreferrer" id="tishman"><img src="/images/work/tishman.png" alt="Studio by Tishman Speyer" /></a>
                    </Col>
                    <Col xs="12" sm="6">
                        <h2>Studio by Tishman Speyer</h2>

                        <p>The ability to rent office real estate to corporations didn't exist before we provided our solution. I worked on multiple features and bug fixes for this website using mainly React and TailwindCSS. Although I worked on most of the site, I was notably involved in adding many enhancements to both the checkout flow and the filters for both the product landing page and the building detail page using using React Query, working closely with backend developers to utilize multiple endpoints.</p>
                        <h3>Skills used</h3>
                        <p>React, Next JS, Tailwind CSS, React Query, React Testing Library, Figma</p>
                        <Button href="https://yourstudio.com" target="_blank" rel="noreferrer">Visit Site</Button>
                    </Col>
                </Row>
            </div>

            <hr />

            {/* May 2023 */}
            <div className="item">
                <Row>
                    <Col xs="12" sm="6">
                        <a href="https://www.kraftmacandcheese.com/" target="_blank" rel="noreferrer" id="kmc"><img src="/images/work/kmc.png" alt="Kraft Mac N Cheese" /></a>
                    </Col>
                    <Col xs="12" sm="6">
                        <h2>Kraft Mac N Cheese</h2>

                        <p>I helped with this Heinz brand to build a number of components as well as a number of CSS animations using Framer.</p>
                        <h3>Skills used</h3>
                        <p>React, Framer Motion, CSS Animations</p>
                        <Button href="https://www.kraftmacandcheese.com/" target="_blank" rel="noreferrer">Visit Site</Button>
                    </Col>
                </Row>
            </div>

            <hr />
            {/* April 2020 - July 2020 */}
            {/* <div className="item">
                <Row>
                    <Col xs="12" sm="6">
                        <a href="https://www.theherohaven.com/" target="_blank" rel="noreferrer" id="herohaven"><img src="/images/work/herohaven.png" alt="Hero Haven" /></a>
                    </Col>
                    <Col xs="12" sm="6">
                        <h2>The Hero Haven</h2>

                        <p>I built this site from the ground up using both frontend, backend and some design skills. I built the backend using Node JS, creating all the endpoints call various external APIs to fetch data. This was then drawn into a React front end application that comprised of pages that were formed with a range of newly built components. A reducer was used from Context API to manage the various states of the site. I was also involved in designing the mobile experience, the homepage, as well as the profile page.</p>
                        <h3>Skills used</h3>
                        <p>React, Context API, Node JS, LESS, Photoshop</p>
                        <Button href="https://www.theherohaven.com/" target="_blank" rel="noreferrer">Visit Site</Button>
                    </Col>
                </Row>
            </div> */}

        </div>
    )
}

export default WorkPage;