import { BrowserRouter, Routes, Route } from "react-router-dom";
import AboutPage from "./pages/about";
import CodePage from "./pages/code";
import HomePage from "./pages/home";
import TestimonialsPage from "./pages/testimonials";
import Layout from "./pages/layout";


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route 
          path="/" 
          element={<Layout />}
        >
          <Route 
            index 
            element={<HomePage />} 
          />
          <Route 
            path="about" 
            element={<AboutPage />} 
          />
          <Route 
            path="code" 
            element={<CodePage />} 
          />
          <Route 
            path="testimonials" 
            element={<TestimonialsPage />} 
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
