import './styles.scss';
import { FaGithub, FaLinkedin, FaFacebook, FaInstagram, FaSpotify } from "react-icons/fa";


type socialMediaPlatform = "linkedIn" | "spotify" | "facebook" | "github" | "instagram"

interface iSocialMediaLink {
  title: string,
  href: string,
  target: string,
  type: socialMediaPlatform,
}


const SocialMediaLink = ({
  title,
  href,
  target,
  type,
}: iSocialMediaLink) => {

  return (
    <a
      href={href}
      target={target}
      title={title}
      className='fa'
      rel="noreferrer"
    >
      {type === 'linkedIn' &&
        <FaLinkedin />
      }
      {type === 'github' &&
        <FaGithub />
      }
      {type === 'facebook' &&
        <FaFacebook />
      }
      {type === 'spotify' &&
        <FaSpotify />
      }
      {type === 'instagram' &&
        <FaInstagram />
      }
    </a>
  )
};

export default SocialMediaLink;