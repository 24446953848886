import { Outlet } from 'react-router-dom';
import HeroImage from '../components/heroImage';
import Footer from '../components/footer';
import Navigation from '../components/navigation';
import SocialMediaLink from '../components/socialMediaLink';

const Layout = () => {
    return (
        <>
            <header className="header">
                <HeroImage
                    backgroundImg="/images/header.jpg"
                    logo="/images/logo.png"
                    title="Cheekyiguana"
                />
                <div className='socialMediaLinks'>
                    <ul>
                        <li><SocialMediaLink href="http://linkedin.com/in/kyrik" type="linkedIn" title="linkedIn" target="_blank" /></li>
                        <li><SocialMediaLink href="http://github.com/kprecise" type="github" title="github" target="_blank" /></li>
                    </ul>
                </div>
                <div className="intro intro-desktop">
                    <p>Hi! I'm Kyri, a senior FE developer with over 20 years of experience</p>
                </div>
                <Navigation links={
                    [
                        { src: '/', label: 'Home',                subNav: [
                            { src: '#heinz', label: 'Heinz' },
                            { src: '#moderna', label: 'Moderna' },
                            { src: '#telus', label: 'Telus' },
                            { src: '#tryaioli', label: 'Try Ai.Oli' },
                            { src: '#tishman', label: 'Studio by Tishman Speyer' },
                            { src: '#kmc', label: 'Kraft Mac N Cheese' },
                            // { src: '#herohaven', label: 'The Hero Haven' }, 
                            ] },
                        { src: '/about', label: 'About Me' },
                        { src: '/code', label: 'Code' },
                        { src: '/testimonials', label: 'Testimonials' }
                    ]
                } />

            </header>
            <main>
                <div className="intro intro-mobile">
                <p>Hi! I'm Kyri, a senior FE developer with over<br />20 years of experience</p>
                </div>
                <Outlet />
            </main>
            <Footer text="&copy; 2024 Site developed by Kyri Kyriacou" />
        </>
    )
}

export default Layout;

