import '../styles/styles.scss';

const TestimonialsPage = () => {
    return (
        <div className="content">
            <h1>Testimonials</h1>
            <div className="testimonial">
                "Kyri is an experienced, detail-oriented, and a collaborative Senior Frontend Developer. He is a dedicated and hardworking professional who adds substantial value to any team and project he is a part of. I recommend Kyri and believe he would be a valuable asset to any organization."
                <div className="reviewer">
                    Gayan Pathirana, Director of Technology at Apply Digital
                </div>
            </div>
            <hr />
            <div className="testimonial">
                "Working with Kyri was a pleasure and he is the type of person you want on your team. Kyri takes great pride in getting things right, has strong communication skills and knows his stuff. I couldn't recommend Kyri enough and he is a true dual-threat between development skills & soft skills!"
                <div className="reviewer">
                    Kevin Park, Scrum Master Lead at Apply Digital
                </div>
            </div>
            <hr />
            <div className="testimonial">
                "Kyri consistently demonstrated his exceptional technical prowess in front-end frameworks, leaving an indelible mark on the efficiency and quality of our team's output. His deep understanding of user experience design and adherence to web development best practices ensured that our deliverables not only met but exceeded the project's requirements and stakeholder expectations."
                <div className="reviewer">
                    Jonathan Birch, Technology Consultant and Digital Solutions Lead at Apply Digital
                </div>
            </div>
            <hr />
            <div className="testimonial">
                "Kyri and I have worked together at two different companies, Blast Radius and Architech. Each time there was an opportunity to work with Kyri on a project I jumped on the prospect. His dedication to learning and understanding new technologies, driving toward deadlines and never giving work less attention than it deserves are all attributes and the attitude I look forward to when working with Kyri."
                <div className="reviewer">
                    Sean McCullough, Senior Software Engineer at Architech & Blast Radius
                </div>
            </div>
            <hr />
            <div className="testimonial">
                "When working with Kyri, I was impressed by his conscientious approach to work and his attention to detail. He's a great collaborator and often suggested insightful improvements to my designs. On a personal level, I greatly enjoyed working with Kyri because of his outgoing and positive attitude."
                <div className="reviewer">
                    Adam Wood, Art Director at FCV Interactive
                </div>
            </div>
            <hr />
            <div className="testimonial">
                "Kyri is an amazing developer. He developed a huge usability prototype for us in a week right after he joined. The prototype was flawless and was exactly as designed. He listens carefully and implements the requirements piece by piece. I recommend Kyri to anyone who is looking for a smart and up to date front end developer."
                <div className="reviewer">
                    Golnoosh Pourshahid, Manager of User Experience Design and Strategy, TD
                </div>
            </div>
        </div>
    )
}

export default TestimonialsPage;