
const CodePage = () => {


    return (
        <div className="content code">
            <h1>Code</h1>
            <p>I take pride in writing good, clean, re-usable code. I have listed some basic examples of my work to demonstrate.</p>
            <h2>Component Markup</h2>
            <p>Here is typically how I'd write my markup in <strong>Next JS</strong> with <strong>Typescript</strong> and <strong>Tailwind CSS</strong>. It illustrates <strong>Accessibility</strong> considerations, clean structure, indentation, comments and fully validates using <strong>ESLint</strong>.</p>
            <img src="/images/code/componentMarkup.png" alt="A code example of good markup and logic" />
            <h2>React Routing</h2>
            <p>Using <strong>React router</strong>, here's a simple implementation:</p>
            <img src="/images/code/reactRouting.png" alt="A code example that uses react router to define all page routes" />
            <h2>Interface and Types</h2>
            <p>With the use of <strong>Typescript</strong>, this is a basic setup that demonstrates how to create an interface with multiple properties that make use of strings, an array of strings, date and predefined types:</p>
            <img src="/images/code/interfaceType.png" alt="A code example using Typescript interface and type" />
            <h2>State Management (Redux)</h2>
            <p>Using <strong>Javascript</strong>, here is an example of a reducer with <strong>Redux</strong>. Each action type populates a payload with the existing state and extracted custom states.</p>
            <p><img src="/images/code/reduxReducer.png" alt="A code example of a reducer with Redux" /></p>
            <h2>State Management (Zustand) </h2>
            <p>Using <strong>Zustand</strong> for state management, the following example gets a fetcher from a zustand store which is then populated with data that is generated from the router query id. Once data is available the loading state become false and shows the data from zustand hook.</p>
            <img src="/images/code/zustand.png" alt="A code example using Zustand for state management" />
            <h2>Page layout</h2>
            <p>This layout is typical of a <strong>Next.JS</strong> project with components, data and styles, metadata and globally used components:</p>
            <img src="/images/code/pageLayout.png" alt="" />
            <h2>API Backend - Populating endpoints</h2>
            <p>Here is a demonstration of how to populate various endpoints: </p>
            <img src="/images/code/apiEndpointBackend1.png" alt="A code example using Javascript that populates an endpoint" />
            <h2>API Backend - Making data available to frontend</h2>
            <p>In this example, the data from all the endpoints is extracted using multiple promises using <strong>Javascript</strong>. The data is then looped through to get all the required properties that are collected in an array. This is then made available to the frontend.</p>
            <img src="/images/code/apiEndpointBackend2.png" alt="A code example using Javascript that demonstrates how to call and collect data from an external API so that this is available to the frontend" />
            <h2>API frontend</h2>
            <p>Using <strong>Javascript</strong>, the API endpoint is fetched and passed to a hook when a promise is resolved:</p>
            <img src="/images/code/apiEndpointFrontend.png" alt="A code example using Javascript that illustrates how to get data into the front end from an API endpoint" />
            <h2>Utility Function</h2>
            <p>Below, is a basic <strong>Javascript</strong> utility function that takes a string and returns an image source string based on if there is a complete match or if two words match: </p>
            <img src="/images/code/utilityFunction.png" alt="A code example of a utility function" />
            <h2>CSS</h2>
            <p>Here's a good example of some well formed <strong>CSS</strong> that uses <strong>Sass</strong> and utilizes predeined fonts, as well as imported colours and mixins.</p>
            <img src="/images/code/sass.png" alt="A code example using Sass" />
            <h2>Unit Tests</h2>
            <p>With the use of <strong>React Testing Library</strong> and <strong>Jest</strong>, multiple test cases are listed for a single component such as generating a snapshot, ensuring the correct active item is selected, a sub nav exists / doesn't exist depending on what the data property receives:</p>
            <img src="/images/code/unitTests.png" alt="A code example of unit tests with React Testing Library and Jest" />
        </div>
    )
}

export default CodePage;
