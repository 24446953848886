import '../styles/styles.scss';

const AboutPage = () => {
    return (

        <div className="content">
            <h1>About Me</h1>
            <p>I'm an established senior front end developer with thorough attention to detail and dedication to continuous improvement. I always try to find the most efficient approach when writing logic and match mocks to the pixel, raising design related considerations at an early stage.</p><p>I build responsive websites across all popular browsers and platforms and have played a key role in delivering high quality user experiences throughout my career. I am an effective member of a team, always happy to help my peers and can deliver large features independently too. My core skills are React, Javascript (ES6), HTML, CSS, Web Accessibility (AODA) and Responsive Design. I've played a key role in a vast number of successful projects to date.</p>

            <h3>Technical Stack</h3>

            <ul>
                <li>React, Next.JS, Node.js, ES6, Typescript </li>
                <li>Context API, Redux</li>
                <li>HTML 5</li>
                <li>TailwindCSS, Styled Components, LESS, SASS, Stylus, CSS Animations, Framer, Flexbox</li>
                <li>React-Boostrap, HeadlessUI</li>
                <li>AODA, Screenreaders (NVDA, Chrome Vox, JAWS)</li>
                <li>Contentful</li>
                <li>Photoshop, Figma, Sketch</li>
                <li>Git</li>
                <li>Npm, Yarn</li>
                <li>React Testing Library, Jest, Cypress, Enzyme, Mocha</li>
                <li>Litmus, MailChimp</li>
                <li>Technical documentation</li>
                <li>JIRA, Confluence</li>
            </ul>

            <h3>People Skills</h3>
            <ul>
                <li>Strong communication skills with team members from all disciplines and when presenting features to clients</li>
                <li>Extremely hard working and persistent, always willing to go that extra mile</li>
                <li>A good eye for detail when matching mockups</li>
                <li>Proactive  with designers to raise UX concerns and to check in regularly to ensure their vision is met</li>
                <li>A strong collaborator with backend developers to retrieve data for the frontend</li>
                <li>Keen to contribute to my team</li>
                <li>Always happy to help other team members</li>
                <li>Curious, always enjoy learning</li>
            </ul>
        </div>

    )
}

export default AboutPage;