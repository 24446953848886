import React from "react";
import './styles.scss';


interface iFooter {
  text: string,
}

const Footer = ({
  text,
}: iFooter) => (
  <footer>{text}</footer>
)

export default Footer;