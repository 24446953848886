
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import './styles.scss';


interface iNavigation {
  links: iNavElement[];
}

type iNavElement =
  {
    src: string,
    label: string,
    subNav?: iNavElementSecondary[]
  }

type iNavElementSecondary = {
  src: string,
  label: string,
}


const Navigation = ({ links }: iNavigation) => {
  const [navDisplay, setNavDisplay] = useState<boolean>(false)
  const [mobile, setMobile] = useState(window.innerWidth <= 767);
  const [stickyClass, setStickyClass] = useState('');

  const currentLocation = useLocation();
  const subNavLinkParent = links.find(obj => obj.subNav !== undefined);
  const isDesktop = !mobile ? 'active' : ''

  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 767);
  }

  const showMobileNav = () => {
    setNavDisplay(true)
  }

  const hideMobileNav = () => {
    setNavDisplay(false)
  }

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 0 ? setStickyClass('sticky-nav') : setStickyClass('');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);
    return () => window.removeEventListener('scroll', stickNavbar);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, [mobile]);

  return (
    <>
      {mobile &&
        <div className="hambMenu" onClick={!navDisplay ? showMobileNav : hideMobileNav}>
          {navDisplay ? <span className='closeBtn'></span> : <span className="hambLines"></span>}
        </div>
      }
      <nav className={navDisplay ? 'active fadeIn' : ''}>
        <ul>
          {
            links.map(item => {
              return (
                <li key={'nav-item-' + item.label}>
                  <Link to={item.src} onClick={mobile ? hideMobileNav : () => ('')}>{item.label}</Link>
                  {(mobile && item.subNav || currentLocation.pathname === subNavLinkParent?.src && item.subNav) &&
                    <ul className={mobile ? 'secondary' : `secondary desktop ${isDesktop} ${stickyClass}`}>

                      {
                        item.subNav.map(itemSecondary => {
                          return (
                            <li key={'nav-item-' + itemSecondary.label}>
                              <HashLink to={item.src + itemSecondary.src} onClick={mobile ? hideMobileNav : () => console.log('')}>{itemSecondary.label}</HashLink>
                            </li>
                          )
                        })
                      }
                    </ul>
                  }
                </li>
              )
            })}
        </ul>
      </nav>

    </>
  )
}

export default Navigation;
